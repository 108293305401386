import logo from './images/logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


function App() {

  const [currentIndexSlider, setCurrentIndexSlider] = useState(0);


  const nextSlide = () => {
    setCurrentIndexSlider((prevIndex) => (prevIndex + 1) % 7);
  };
  const prevSlide = () => {
    setCurrentIndexSlider((prevIndex) => (prevIndex - 1 + 7) % 7);
  };


  const [currentIndexTowns, setCurrentIndexTowns] = useState(0);


  const nextSlideTowns = () => {
    setCurrentIndexTowns((prevIndex) => (prevIndex + 1) % 3);
  };
  const prevSlideTowns = () => {
    setCurrentIndexTowns((prevIndex) => (prevIndex - 1 + 3) % 3);
  };


  useEffect(() => {

    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => {
      console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to, element) => {
      console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  // Defining functions to perform different types of scrolling.
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  const scrollTo = () => {
    scroll.scrollTo(100); // Scrolling to 100px from the top of the page.
  };

  const scrollMore = () => {
    scroll.scrollMore(100); // Scrolling an additional 100px from the current scroll position.
  };

  // Function to handle the activation of a link.
  const handleSetActive = (to) => {
    console.log(to);
  };


  return (
    <>
      <nav>
        <div className='logo'><img src={logo} />ГромадіЯ</div>
        <ul>
          <li><Link
            to="nav1"
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >Завантажити</Link></li>
          <li><Link
            to="nav2"
            spy={true}
            smooth={true}
            offset={-170}
            duration={500}
          >Абоненти</Link></li>
          <li><Link
            to="nav3"
            spy={true}
            smooth={true}
            offset={-170}
            duration={500}
          >Модулі</Link></li>
          <li><Link
            to="nav4"
            spy={true}
            smooth={true}
            offset={-170}
            duration={500}
          >Підключення</Link></li>
          <li><Link
            to="nav5"
            spy={true}
            smooth={true}
            offset={-170}
            duration={500}
          >Контакти</Link></li>
          <li className='LiWebVersion'><a href=''><div className='WebVersion'><img src={require('./images/globus.png')} />Веб-версія</div></a></li>
        </ul>
      </nav>
      <main>

        <div id="nav1" className='block1'>
          <div>
            <h1><img src={logo} />ГромадіЯ</h1>
            <h2>
              <span>Унікальний цифровий сервіс  для </span>
              об’єднаних територіальних громад та міст

              <div id='download'><a>Завантажити</a></div>

            </h2>
          </div>
          <img src={require('./images/block1_phone.png')}></img>
          <div id="mobileDownload"><a>Завантажити додаток</a></div>
        </div>
        <div id="nav2" className='abonents'>
          <h1>Абоненти</h1>
          <img src={require('./images/ukraine.png')} className='ukraineimg'></img>
          <div className='abonents_info'>
            <ul>
              <li><span>10</span>   громад</li>
              <li><span>10</span>   міст</li>
              <li><span>12000</span>   користувачів</li>
            </ul>
          </div>
          <div className='slider_hider_towns'>
            <div className='slider_blocks_towns' style={{ left: "-" + currentIndexTowns * 100 + "%" }}>

              <ul className='abonents_gromadi'>
                <li><img src={require('./images/bucha.png')} />Бучанська міська<br />громада</li>
                <li><img src={require('./images/vishneve.png')} />Вишнівська міська<br />громада</li>
                <li><img src={require('./images/bilozirska.png')} />Білозірська сільська<br />громада</li>
              </ul>
              <ul className='abonents_gromadi'>
                <li><img src={require('./images/bucha.png')} />Бучанська міська<br />громада</li>
                <li><img src={require('./images/vishneve.png')} />Вишнівська міська<br />громада</li>
                <li><img src={require('./images/bilozirska.png')} />Білозірська сільська<br />громада</li>
              </ul>
              <ul className='abonents_gromadi'>
                <li><img src={require('./images/bucha.png')} />Бучанська міська<br />громада</li>
                <li><img src={require('./images/vishneve.png')} />Вишнівська міська<br />громада</li>
              </ul>

            </div>
          </div>
          <div className='abonents_nav'>
            <button onClick={prevSlideTowns}> <img src={require('./images/arrow.png')} className='arrow' /></button>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <div className='circle' style={{ backgroundColor: currentIndexTowns == 0 ? "#000" : "#9B9B9B" }}></div>
              <div className='circle' style={{ backgroundColor: currentIndexTowns == 1 ? "#000" : "#9B9B9B" }}></div>
              <div className='circle' style={{ backgroundColor: currentIndexTowns == 2 ? "#000" : "#9B9B9B" }}></div>
            </div>

            <button onClick={nextSlideTowns}><img src={require('./images/arrow.png')} className='arrow' style={{ transform: " rotate(180deg)", }} /></button>
          </div>
        </div>
        <div id="nav3" className='modules'>
          <h1>Модулі</h1>
          <div className='slider'>
            <button onClick={prevSlide}><img src={require('./images/arrow.png')} className='arrow' /></button>
            <div className='slider_hider'>
              <div className='slider_blocks' style={{ left: "-" + currentIndexSlider * 100 + "%" }}>
                <div className='modules_info'>
                  <img src={require('./images/block1_phone.png')} className='module_img1'></img>
                  <div className='modules_info_details'>
                    <h1>Головне меню</h1>
                    <ul>
                      <li> <img src={require('./images/circle.png')} className='circleImg' />локальна інформація про погоду</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />тривожна кнопка</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />сповіщення</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />карта укриттів</li>
                    </ul>
                  </div>
                  <img className='slide_logo' src={require('./images/Slide1_logo.png')}></img>
                </div>

                <div className='modules_info'>
                  <img src={require('./images/block2_phone.png')} className='module_img1'></img>
                  <div className='modules_info_details'>
                    <h1>Транспорт</h1>
                    <ul>
                      <li> <img src={require('./images/circle.png')} className='circleImg' />електрички, автобуси, тощо</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />інтерактивний пошук по станціям</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />інформація про кожен маршрут</li>
                    </ul>
                  </div>
                  <img className='slide_logo' src={require('./images/Slide2_logo.png')}></img>
                </div>

                <div className='modules_info'>
                  <img src={require('./images/block3_phone.png')} className='module_img1'></img>
                  <div className='modules_info_details'>
                    <h1>НОВИНИ ТА ПОДІЇ</h1>
                    <ul>
                      <li> <img src={require('./images/circle.png')} className='circleImg' />архів новин та подій</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />інтегрований пошук</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />можливість додавати події в календар у смартфоні</li>
                    </ul>
                  </div>
                  <img className='slide_logo' src={require('./images/Slide3_logo.png')}></img>
                </div>
                <div className='modules_info'>
                  <img src={require('./images/block4_phone.png')} className='module_img1'></img>
                  <div className='modules_info_details'>
                    <h1>Онлайн петиції</h1>
                    <ul>
                      <li> <img src={require('./images/circle.png')} className='circleImg' />легке створення та модерація</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />лише резиденти можуть голосувати</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />онлайн результати голосуваннята відкриті відповіді влади</li>
                    </ul>
                  </div>
                  <img className='slide_logo' src={require('./images/Slide4_logo.png')}></img>
                </div>

                <div className='modules_info'>
                  <img src={require('./images/block5_phone.png')} className='module_img1'></img>
                  <div className='modules_info_details'>
                    <h1>Повідомлення про проблему</h1>
                    <ul>
                      <li> <img src={require('./images/circle.png')} className='circleImg' />зручна подача</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />сповіщення відповідальних осіб</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />оновлення статусу та відповідь на звернення у профілі</li>
                    </ul>
                  </div>
                  <img className='slide_logo' src={require('./images/Slide5_logo.png')}></img>
                </div>

                <div className='modules_info'>
                  <img src={require('./images/block6_phone.png')} className='module_img1'></img>
                  <div className='modules_info_details'>
                    <h1>опитування</h1>
                    <ul>
                      <li> <img src={require('./images/circle.png')} className='circleImg' />швидке створення</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />зручний метод проведення соціологічних досліджень</li>
                    </ul>
                  </div>
                  <img className='slide_logo' src={require('./images/Slide6_logo.png')}></img>
                </div>

                <div className='modules_info'>
                  <img src={require('./images/block7_phone.png')} className='module_img1'></img>
                  <div className='modules_info_details'>
                    <h1>ЦНАП</h1>
                    <ul>
                      <li> <img src={require('./images/circle.png')} className='circleImg' />довідник послуг</li>
                      <li><img src={require('./images/circle.png')} className='circleImg' />запис до електронної черги, візит за талоном у додатку</li>
                    </ul>
                  </div>
                  <img className='slide_logo' src={require('./images/Slide7_logo.png')}></img>
                </div>
              </div>
            </div>
            <button onClick={nextSlide}><img src={require('./images/arrow.png')} className='arrow' style={{ transform: " rotate(180deg)", }} /></button>
          </div>
          <div style={{ flexDirection: 'row', width: '100%', display: 'flex', height: '100%', justifyContent: 'center' }}>
            <div className='circle' style={{ backgroundColor: currentIndexSlider == 0 ? "#000" : "#9B9B9B" }}></div>
            <div className='circle' style={{ backgroundColor: currentIndexSlider == 1 ? "#000" : "#9B9B9B" }}></div>
            <div className='circle' style={{ backgroundColor: currentIndexSlider == 2 ? "#000" : "#9B9B9B" }}></div>
            <div className='circle' style={{ backgroundColor: currentIndexSlider == 3 ? "#000" : "#9B9B9B" }}></div>
            <div className='circle' style={{ backgroundColor: currentIndexSlider == 4 ? "#000" : "#9B9B9B" }}></div>
            <div className='circle' style={{ backgroundColor: currentIndexSlider == 5 ? "#000" : "#9B9B9B" }}></div>
            <div className='circle' style={{ backgroundColor: currentIndexSlider == 6 ? "#000" : "#9B9B9B" }}></div>
          </div>
        </div>
        <div id="nav4" className='conncect'>
          <h1>
            Підключення
          </h1>
          <div className='conncect_data'>
            <div className='conncect_data_left'>
              <p>
                <span>ГромадіЯ</span> - простий та якісний  спосіб<br />
                цифровізації громад та міст!
                <br /><br />
                <span>Лише заповнюйте заявку - все<br />
                  інше ми беремо на себе!</span>
              </p>
              <h1><img src={logo} />ГромадіЯ</h1>
            </div>
            <div className='conncect_data_right'>
              <h3>Назва громади чи міста</h3>
              <input></input>
              <h3>Ім’я та прізвище</h3>
              <input></input>
              <h3>Номер телефону</h3>
              <input></input>
              <h3>Електронна пошта</h3>
              <input></input>
              <button>Долучитись!</button>
            </div>
          </div>
        </div>
      </main>
      <div id="nav5" className='conctacts'>
        <div className='conctacts_top'>
          <h1>
            Контакти
          </h1>
          <div className='conctacts_top_data'>
            <div className='conctacts_top_data_left'>
              <h2>ТОВ "ГромадіЯ"</h2>
              <p>ЄДРПОУ 45352494, Україна, 08147, Київська<br /> область, Бучанський район, село Софіївська<br /> Борщагівка, вулиця Сагайдачного, будинок 20-A</p>
              <h3>Політика конфіденційності</h3>
            </div>
            <div className='conctacts_top_data_right'>
              <a href='tel:+380965702999'><h2><img src={require('./images/phone.png')} />+380 96 570 29 99</h2></a>
              <a href='mailto:manager@gromadia.in.ua'><h2><img src={require('./images/mail.png')} />manager@gromadia.in.ua</h2></a>
              <a href='https://t.me/gromadia'><div className='tgbutton'><img src={require('./images/telegrma.png')} />Допомога з роботою сервісу</div></a>
            </div>
          </div>
          <h5 className='copyright'>COPYRIGHT @ 2024 ТОВ “ГромадіЯ” - ВСІ ПРАВА ЗАХИЩЕНО</h5>
        </div>
        <div className='conctacts_bottom'>
          <img src={require('./images/adaptysys.png')} />
        </div>
      </div>
    </>
  );
}

export default App;
